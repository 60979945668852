<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Costo Total del Proyecto')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
						<DataTable :value="descripciones" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column :header="$t('Descripción')">
								<template #body="slotProps">
                                    <InputText v-model="slotProps.data.titulo" type="text"/>
								</template>
							</Column>
							<Column :header="$t('Valor')">
								<template #body="slotProps">
                                    <InputText v-model="slotProps.data.valor" type="number" style="max-width:100px;"/>
								</template>
							</Column>
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Delete(slotProps.data)"/>
								</template>
							</Column>
						</DataTable>
                        <small v-show="validationErrors.descripciones && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-4 p-md-2" v-if="!Dialog">
						<Button :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary"  @click="Agregar()"/>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			submitted: false,
            validationErrors: {},
			Dialog: false,
			descripciones: [],
			descripcion: {
				titulo: null,
				valor: 0,
			},
            size: '40vw',
			url: null,
			contador: 0,
        }
    },
	created() {
		this.contador = 0;
		this.url = url;
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
	},
    methods: {
		Agregar() {
            this.descripciones.push({titulo:  '', valor: 0});

		},
		Delete(selec) {
			this.descripciones = this.descripciones.filter(val => val !== selec);
		},
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {descripciones: this.descripciones}, pageIndex: 2});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 2});
        },
        validateForm() {
            if (this.descripciones.length==0)
               this.validationErrors['descripciones'] = true;
            else
               delete this.validationErrors['descripciones'];
            return !Object.keys(this.validationErrors).length;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
    }
}
</script>